<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-data-table :headers="headers" :items="menu" class="elevation-1">
            <template v-slot:[`item.enable`]="{ item }">
              <v-icon v-if="item.enable" small color="primary">
                {{ check }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <span class="primary--text">{{ titleMenuTable }}</span>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="openEdit(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
          </v-data-table>

          <v-dialog
            v-if="editMenuAppDialog"
            v-model="editMenuAppDialog"
            max-width="500px"
            @keydown.esc="editMenuAppDialog = false"
          >
            <v-card>
              <v-card-title>
                <span class="pl-1 primary--text">{{ formEditTitleMenu }}</span>
              </v-card-title>

              <v-card-text class="pb-0">
                <v-form v-model="isFormValid" lazy-validation>
                  <v-row>
                    <v-col class="pb-0" cols="12" sm="6" md="12">
                      <v-text-field
                        v-model.trim="itemMenuSelected.title"
                        label="Título"
                        dense
                        outlined
                        :rules="
                          rules.required.concat([
                            rules.maxLength(itemMenuSelected.title, 45),
                            rules.requiredTrim(itemMenuSelected.title),
                          ])
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0" cols="12" sm="6" md="12">
                      <v-text-field
                        v-model.trim="itemMenuSelected.subTitle"
                        label="Subtítulo"
                        dense
                        outlined
                        :rules="
                          rules.required.concat([
                            rules.maxLength(itemMenuSelected.subTitle, 45),
                            rules.requiredTrim(itemMenuSelected.subTitle),
                          ])
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-0" cols="3" sm="6" md="3">
                      <v-text-field
                        v-model="itemMenuSelected.order"
                        label="Orden"
                        dense
                        outlined
                        :disabled="!itemMenuSelected.enable"
                        type="number"
                        :rules="rules.required.concat(rules.positiveNumber)"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="3" sm="6" md="4">
                      <v-switch
                        hide-details
                        class="pt-0 mt-1"
                        v-model="itemMenuSelected.enable"
                        label="Disponible"
                        dense
                        outlined
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined @click="close"> Cancelar </v-btn>
                <v-btn
                  :disabled="!isFormValid"
                  color="primary"
                  @click="saveEditMenuApp()"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-data-table
            :headers="headers1"
            :items="tabOptions"
            class="pt-3 mt-3 elevation-1"
          >
            <template v-slot:[`item.enable`]="{ item }">
              <v-icon v-if="item.enable" small color="primary">
                {{ check }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <span class="primary--text">{{ titleTabsTable }}</span>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="openEdit(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog
            v-if="editTabOptionDialog"
            v-model="editTabOptionDialog"
            max-width="500px"
            @keydown.esc="editTabOptionDialog = false"
          >
            <v-card>
              <v-card-title>
                <span class="pl-1 primary--text">{{ formEditTitleTabs }}</span>
              </v-card-title>

              <v-card-text class="pb-0">
                <v-form v-model="isFormValid" lazy-validation>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        v-model.trim="itemTabSelected.title"
                        label="Título"
                        item-text="value"
                        item-value="id"
                        dense
                        outlined
                        :rules="
                          rules.required.concat([
                            rules.requiredTrim(itemTabSelected.title),
                          ])
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-switch
                        v-model="itemTabSelected.enable"
                        label="Disponible"
                        item-text="value"
                        item-value="id"
                        class="mt-0"
                        dense
                        outlined
                        :disabled="
                          itemTabSelected.cod == 'home' ||
                          itemTabSelected.cod == 'profile'
                        "
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined @click="close"> Cancelar </v-btn>
                <v-btn
                  :disabled="!isFormValid"
                  color="primary"
                  @click="saveEditTabOption()"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "MenuTabsSeparated",
  components: { PageHeader, GoBackBtn, Ayuda },

  data: () => ({
    isFormValid: true,
    itemMenuSelected: null,
    itemTabSelected: null,
    descripcionEditada: null,
    formEditTitleMenu: "Editar menú",
    formEditTitleTabs: "Editar tab",
    editMenuAppDialog: false,
    editTabOptionDialog: false,
    routeToGo: "ConfiguracionAppBenef",
    titleTabsTable: "Tabs",
    titleMenuTable: "Menú",
    check: enums.icons.CHECK_OUTLINE,
    title: enums.titles.OPCIONES_MENU_TABS,
    optionCode: enums.webSiteOptions.MENU_TABS,
    showHelp: false,
    showExpand: false,
    showIcon: true,
    menu: [],
    tabOptions: [],
    rules: rules,
    headers: [
      {
        text: "Título",
        align: "start",
        value: "title",
      },
      { text: "Subtítulo", value: "subTitle" },
      { text: "Orden", value: "order" },
      { text: "Disponible", value: "enable", align: "center" },
      { text: "Acciones", value: "actions", align: "end" },
    ],
    headers1: [
      {
        text: "Título",
        align: "start",
        value: "title",
      },
      { text: "Disponible", value: "enable", align: "center" },
      { text: "Acciones", value: "actions", align: "end", sortable: false },
    ],
    canEdit: false,
  }),
  mounted() {
    if (this.$route.params.canEdit == undefined) {
      this.$router.push({
        name: "ConfiguracionAppBenef"
      });
    }
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.canEdit = this.$route.params.canEdit;
    this.loadPage();
  },
  methods: {
    ...mapActions({
      getMenuApp: "appBenef/getMenuApp",
      getTabOptions: "appBenef/getTabOptions",
      updateTabOptions: "appBenef/updateTabOptions",
      updateMenuApp: "appBenef/updateMenuApp",
      setAlert: "user/setAlert",
    }),
    async loadPage() {
      const res = await this.getMenuApp();
      this.menu = res;
      const res1 = await this.getTabOptions();
      this.tabOptions = res1;
    },
    openEdit(item) {
      if (item.subTitle != null) {
        this.itemMenuSelected = { ...item };
        this.editMenuAppDialog = true;
      } else {
        this.itemTabSelected = { ...item };
        this.editTabOptionDialog = true;
      }
    },
    async saveEditMenuApp() {
      const data = {
        id: this.itemMenuSelected.id,
        title: this.itemMenuSelected.title,
        subTitle: this.itemMenuSelected.subTitle,
        order: this.itemMenuSelected.order,
        enable: this.itemMenuSelected.enable,
      };
      const res = await this.updateMenuApp(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.editMenuAppDialog = false;
      }
      this.loadPage();
    },
    async saveEditTabOption() {
      const dataTab = {
        id: this.itemTabSelected.id,
        title: this.itemTabSelected.title,
        enable: this.itemTabSelected.enable,
      };
      const res = await this.updateTabOptions(dataTab);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.editTabOptionDialog = false;
      }
      await this.loadPage();
    },
    close() {
      this.editTabOptionDialog = false;
      this.editMenuAppDialog = false;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep label {
  margin-bottom: 0% !important;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>