var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{staticClass:"pb-3",attrs:{"title":_vm.title}}),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.menu},scopedSlots:_vm._u([{key:"item.enable",fn:function(ref){
var item = ref.item;
return [(item.enable)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.check)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.titleMenuTable))])])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEdit(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e()]}}],null,true)}),(_vm.editMenuAppDialog)?_c('v-dialog',{attrs:{"max-width":"500px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.editMenuAppDialog = false}},model:{value:(_vm.editMenuAppDialog),callback:function ($$v) {_vm.editMenuAppDialog=$$v},expression:"editMenuAppDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitleMenu))])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Título","dense":"","outlined":"","rules":_vm.rules.required.concat([
                          _vm.rules.maxLength(_vm.itemMenuSelected.title, 45),
                          _vm.rules.requiredTrim(_vm.itemMenuSelected.title) ])},model:{value:(_vm.itemMenuSelected.title),callback:function ($$v) {_vm.$set(_vm.itemMenuSelected, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"itemMenuSelected.title"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Subtítulo","dense":"","outlined":"","rules":_vm.rules.required.concat([
                          _vm.rules.maxLength(_vm.itemMenuSelected.subTitle, 45),
                          _vm.rules.requiredTrim(_vm.itemMenuSelected.subTitle) ])},model:{value:(_vm.itemMenuSelected.subTitle),callback:function ($$v) {_vm.$set(_vm.itemMenuSelected, "subTitle", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"itemMenuSelected.subTitle"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"Orden","dense":"","outlined":"","disabled":!_vm.itemMenuSelected.enable,"type":"number","rules":_vm.rules.required.concat(_vm.rules.positiveNumber)},model:{value:(_vm.itemMenuSelected.order),callback:function ($$v) {_vm.$set(_vm.itemMenuSelected, "order", $$v)},expression:"itemMenuSelected.order"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3","sm":"6","md":"4"}},[_c('v-switch',{staticClass:"pt-0 mt-1",attrs:{"hide-details":"","label":"Disponible","dense":"","outlined":""},model:{value:(_vm.itemMenuSelected.enable),callback:function ($$v) {_vm.$set(_vm.itemMenuSelected, "enable", $$v)},expression:"itemMenuSelected.enable"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"disabled":!_vm.isFormValid,"color":"primary"},on:{"click":function($event){return _vm.saveEditMenuApp()}}},[_vm._v(" Guardar ")])],1)],1)],1):_vm._e(),_c('v-data-table',{staticClass:"pt-3 mt-3 elevation-1",attrs:{"headers":_vm.headers1,"items":_vm.tabOptions},scopedSlots:_vm._u([{key:"item.enable",fn:function(ref){
                        var item = ref.item;
return [(item.enable)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.check)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.titleTabsTable))])])]},proxy:true},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEdit(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e()]}}],null,true)}),(_vm.editTabOptionDialog)?_c('v-dialog',{attrs:{"max-width":"500px"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.editTabOptionDialog = false}},model:{value:(_vm.editTabOptionDialog),callback:function ($$v) {_vm.editTabOptionDialog=$$v},expression:"editTabOptionDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitleTabs))])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Título","item-text":"value","item-value":"id","dense":"","outlined":"","rules":_vm.rules.required.concat([
                          _vm.rules.requiredTrim(_vm.itemTabSelected.title) ])},model:{value:(_vm.itemTabSelected.title),callback:function ($$v) {_vm.$set(_vm.itemTabSelected, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"itemTabSelected.title"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Disponible","item-text":"value","item-value":"id","dense":"","outlined":"","disabled":_vm.itemTabSelected.cod == 'home' ||
                        _vm.itemTabSelected.cod == 'profile'},model:{value:(_vm.itemTabSelected.enable),callback:function ($$v) {_vm.$set(_vm.itemTabSelected, "enable", $$v)},expression:"itemTabSelected.enable"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"disabled":!_vm.isFormValid,"color":"primary"},on:{"click":function($event){return _vm.saveEditTabOption()}}},[_vm._v(" Guardar ")])],1)],1)],1):_vm._e()],1)],1),_c('v-col',{staticClass:"to-right pt-7 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }